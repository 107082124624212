import React from "react";
import * as Icons from "react-icons/vsc";

const SidebarPath = ({ link, iconName }) => {
  const Icon = Icons[iconName];

  const handleClick = () => {
    window.open(link.url, "_blank");
  };

  return (
    <div
      onClick={handleClick}
      className={`relative px-3 mx-5 py-2 text-sm font-medium text-richblack-300 cursor-pointer
       transition-all duration-300
              hover:scale-110 hover:shadow-[0_0_1em_0.45em_rgba(0,0,0,0.1)] hover:bg-[radial-gradient(circle_at_bottom,rgba(50,100,180,0.5)_10%,#212121_70%)]`}
    >
      <div className="flex flex-row items-center gap-x-2 hover:text-richblack-5 transition-all duration-200">
        <Icon className="text-lg" />
        <span>{link.name}</span>
      </div>
    </div>
  );
};

export default SidebarPath;
